
import {computed, defineComponent, onMounted, Ref, ref} from 'vue';
import {useRouter} from "vue-router";
import store from "@/store";
import {TGamesClassifierItem} from "@/api/modules/game/types";
import GameCard from "@/components/UI/game-card.vue";
import UsersStatistick from "@/components/users/UsersStatistick.vue";
import AllowUserGame from "@/components/users/AllowUserGame.vue";
import AddUser from "@/components/users/AddUser.vue";


export default defineComponent({
	name: 'Games',
	components: {AddUser, AllowUserGame, UsersStatistick, GameCard},
	setup() {
		onMounted(() => {
			store.dispatch('users/loadCurrentUser');
		});

		const router = useRouter();
		const goTo = (game_id: string) => {
			router.push({ name: 'GamesIntroWithID', params: { game_id: game_id } })
		}

		const showRules = ref(false);

		const rulesCode: Ref<null|string> = ref(null);

		const user = computed(() => store.getters['users/getCurrentUser']);

		const allowedGames = computed(() => {
			return user.value?.rel_allowed_games.map((v: TGamesClassifierItem) => v.code_name) ?? [];
		});

		const checkAllowed = computed(() => {
			return {
				volume: allowedGames.value.includes("volume"),
				palitra: allowedGames.value.includes("palitra"),
			}
		})

		return {
			goTo,
			checkAllowed,
			allowedGames,
			user,
			showRules,
			rulesCode
		}
	}
});
