<template>
    <div class="game-card-main">
        <div class="game-card">
            <div class="game-card-image"><slot name="image"></slot></div>
            <div class="game-card-body"><slot name="body"></slot></div>
        </div>
    </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">
.game-card-main {
    display: flex;
    justify-items: center;
    align-items: center;
}

.game-card {
    display: grid;
    width: 100%;
    min-height: 200px;
    grid-template-columns: 400px 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "image body";
    justify-items: stretch;
    align-items: stretch;
    border-radius: 10px !important;
    border: 1px solid rgba(0, 57, 178, 0.3);
    background-color: #ffffff;
    box-shadow: 0 4px 10px rgba(0,0,0,.13),0 0 2px rgba(0,0,0,.16),0 2px 6px rgba(0,0,0,.22)!important;

    &-image {
        grid-area: image;
        border-top-left-radius: 10px !important;
        border-bottom-left-radius: 10px !important;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }

    &-body {
        grid-area: body;
        border-top-right-radius: 10px !important;
        border-bottom-right-radius: 10px !important;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        background-color: rgba(0, 0, 0, 0);
    }
}
</style>